#trigger-top-banner {
  min-height: 100vh;
  height: max-content;
}
.top-banner {
  @import "../../../styles/text.scss";

  padding: 1rem 2.5rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;

  position: sticky;
  top: 0;
  max-height: 100vh;

  @media (max-width: 991px) {
    padding-top: 13.563rem;
    padding-bottom: 2rem;
    padding-left: 2%;
    padding-right: 2%;
  }

  > video {
    height: 100%;
    width: 100%;

    object-fit: cover;
    object-position: center;

    position: absolute;
    top: 0;
    left: 0;
  }

  div#home-contact {
    padding: 0;
    width: 100%;
    flex-direction: row;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    button {
      @media (min-width: 992px) {
        transform: translate(1rem, 0px);
        button:hover {
          opacity: 0.9;
        }
      }
    }
  }

  input#email {
    max-height: 2.188rem;
    color: #fff;

    &::placeholder {
      color: #fff;
    }

    @media (min-width: 992px) {
      width: 100%;
      max-width: 42.375rem;
    }

    &:focus-visible {
      border: 1px solid #d9d9d9;
      outline: none;

      &::placeholder {
        color: #d9d9d9;
      }
    }
  }

  &__container {
    position: relative;
    max-width: 82.25rem;
    width: 100%;

    p {
      max-width: 40rem;
      margin: 2rem 0;
    }
  }

  &__form {
    max-height: 2.5rem;
    display: flex;

    width: 100%;

    form {
      width: 100%;
      max-width: 40.5rem;
    }

    @media (max-width: 991px) {
      margin-top: 2rem;
      max-height: 100%;
    }
  }

  &__text {
    // max-width:49.19rem;
    width: 100%;

    h1 {
      position: relative;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 2.625rem;
      }
    }

    p#list-container {
      white-space: nowrap;
      font-weight: 200;
      font-style: italic;
      max-width: 60rem;
    }

    div#items-wrapper {
      font-size: 1.5rem;
      max-width: 60rem;
      width: 100%;
      @media (max-width: 991px) {
        font-size: 1.125rem;
        letter-spacing: -0.01em;
      }
    }
  }
}

#trigger-top-banner-video {
  position: absolute;
  top: 30rem;
}

#trigger-top-banner-text {
  position: absolute;
  top: 34rem;
}

h1#animation {
  display: flex;
  align-items: flex-start;

  span {
    white-space: nowrap;
    font-weight: 200;
    font-style: italic;
    font-size: 5.115rem;
  }

  .container-animation {
    padding-left: 1rem;

    .word {
      opacity: 0;
      position: absolute;
      width: 100%;
      text-align: left;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 2.625rem;
    span {
      font-size: 2.625rem;
    }
    .container-animation {
      padding-left: 0rem;
      margin-top: 0rem;
      height: 3rem;
    }
  }

  .w1 {
    -webkit-animation: w1anim 20s infinite;
    animation: w1anim 20s infinite;
  }

  .w2 {
    -webkit-animation: w2anim 20s infinite;
    animation: w2anim 20s infinite;
  }

  .w3 {
    -webkit-animation: w3anim 20s infinite;
    animation: w3anim 20s infinite;
  }

  .w4 {
    -webkit-animation: w4anim 20s infinite;
    animation: w4anim 20s infinite;
  }

  .w5 {
    -webkit-animation: w5anim 20s infinite;
    animation: w5anim 20s infinite;
  }

  .w6 {
    -webkit-animation: w6anim 20s infinite;
    animation: w6anim 20s infinite;
  }
}

@keyframes w1anim {
  0% {
    opacity: 0;
  }
  8.3% {
    opacity: 1;
  }
  16.6% {
    opacity: 0;
  }
}

@keyframes w2anim {
  16.6% {
    opacity: 0;
  }
  24.9% {
    opacity: 1;
  }
  33.2% {
    opacity: 0;
  }
}

@keyframes w3anim {
  33.2% {
    opacity: 0;
  }
  41.5% {
    opacity: 1;
  }
  49.8% {
    opacity: 0;
  }
}

@keyframes w4anim {
  49.8% {
    opacity: 0;
  }
  58.1% {
    opacity: 1;
  }
  66.4% {
    opacity: 0;
  }
}

@keyframes w5anim {
  66.4% {
    opacity: 0;
  }
  74.7% {
    opacity: 1;
  }
  83% {
    opacity: 0;
  }
}

@keyframes w6anim {
  83% {
    opacity: 0;
  }
  91.3% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

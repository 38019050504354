
.scroll-container {
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: .5rem;
    height: .8rem;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90,0);
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
}
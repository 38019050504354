@import "./styles/vars.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  font-size: 16px;
  background-color: black;

  @media (min-width: 992px) {
    font-size: 1vw;
  }
  @media (min-width: 1920px) {
    font-size: calc(16px + .1vw);
  }
  @media (max-width: 767px) {
    font-size: 4vw;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    font-size: 2vw;
  }
}

body {
  background: url(assets/images/mission/mission-bg.jpg);
  background-repeat: repeat-y;
  background-position: 0 0;
  background-size: 100% auto;

  transition: background 2s;
  transition-timing-function: ease-out;
}
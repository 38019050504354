
.card-list {
  $r: &;
  @import "../../../styles/vars.scss";

  &__item {
    $f: &;
    margin: 0.313rem 0;
    @media(max-width: 991px) {
      &--inner {
        border-radius: 1.25rem;
        border-radius: 1.25rem;
        background: $hubble-dark;
      }
    }
    @media(min-width: 992px) {
      cursor: pointer;
      height: 12.5rem;
      width: 15.625rem;
      margin: 0.3rem;
      perspective: 1000px;
      &--inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
      }
    }

    &--header,
    &--content {
      @media(min-width: 992px) {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
        box-sizing: content-box;
      }
    }

    &--header {
      font-family: $font-h;
      font-style: normal;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.2;

      display: flex;
      align-items: center;

      color: $text-color;
      background: $hubble-purple;

      border-radius: 1.25rem;

      img {
        height: 100%;
        width: 100%;
        max-height: 1.75rem;
        object-fit: contain;
        object-position: center;
      }

      @media(max-width: 991px) {
        display: grid;
        grid-template-columns: 2rem 1fr 1rem;
        grid-gap: 1rem;
        padding: .65rem 1.3rem .65rem .75rem;
        align-items: center;
      }
      @media(min-width: 992px) {
        flex-direction: column;
        align-content: space-between;
        display: grid;

        border-radius: 1.25rem;
        overflow: hidden;
        text-align: left;
        img {
          object-position: left;
        }
        span {
          padding: 2rem 2rem 1rem;

          &:nth-child(2) {
            padding: 1rem 2rem 2.375rem;
          }

        }
        span.arrow {
          display: none;
        }
      }
    }

    &--content {
      background: rgba(255, 255, 255, .1);
      -webkit-border-bottom-right-radius: 1.25rem;
      -webkit-border-bottom-left-radius: 1.25rem;
      -moz-border-radius-bottomright: 1.25rem;
      -moz-border-radius-bottomleft: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      border-bottom-left-radius: 1.25rem;
      display: flex;
      justify-content: center;

      @media(max-width: 991px) {
        overflow: hidden;
        padding: 0rem 2rem;
        max-height: 0;
        transition: max-height .5s, padding .5s;
      }
      @media(min-width: 992px) {
        border-radius: 1.25rem;
        overflow: auto;
        transform: rotateY(180deg);
        padding: 0;
        // border: 0.01rem solid white;
      }

      > p {
        margin: 0;
        font-size: 0.813rem;
        line-height: 1.3;

        display: flex;
        align-items: center;

        color: $text-color;

        @media (min-width: 992px) {
          padding: 2rem 1rem 2rem;
        }
      }
    }

    &--active {
      #{$f}--header {
        @media(max-width: 991px) {
          span.arrow {
            img {
              transform: scaleY(-1);
            }
          }
        }
      }

      #{$f}--content {
        @media(max-width: 991px) {
          max-height: 200vh;
          padding: 1rem 2rem;
          transition: max-height 1s, padding 1s;
        }
      }

      #{$f}--inner {
        @media(min-width: 992px) {
          transform: rotateY(180deg);
        }
      }

    }
  }
}
@font-face {
  font-family: "SF Pro";
  font-weight: normal;
  src: url("../assets/fonts/SF-Pro-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro";
  font-weight: bold;
  src: url("../assets/fonts/SF-Pro-Display-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro";
  font-weight: lighter;
  src: url("../assets/fonts/SF-Pro-Display-Light.otf") format("opentype");
}


$font-h: "SF Pro", sans-serif;
$font-p: "SF Pro", sans-serif;

$hubble-red: #F4526A;
$text-color: white;


$hubble-purple: #5A64FF;
$hubble-dark: #1E1537;
.footer {
    bottom: 0;
    display: flex;
    width: 100%;
    height: 6.25rem;
    color: #fff;
    justify-content: space-between;
    border-top: 1px solid #ffffff;
    box-sizing: border-box;
}

.footer img#footer-logo {
    max-width: 11rem;
}

.footer-items {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;
}

.footer-items span {
    cursor: pointer;
}


@media (max-width: 73.5rem) {
    .container img#footer-logo {
        display: none;
    }

    .footer-items {
        width: 100%;
    }
}

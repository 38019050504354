.navigation {
  display: flex;
  max-height: 4.438rem;
  position: fixed;
  top: 1.875rem;
  z-index: 50;
  width: 100%;
  padding: 0 2.188em;
  box-sizing: border-box;
}
.btn-link {
  background: transparent;
  border: 0;
  padding: 0;
}
.container {
  display: flex;
  justify-content: space-between;
  max-width: 82.25rem;
  width: 100%;
  margin: 0.813rem auto;
  z-index: 40;
}

.container a {
  color: white;
  text-shadow: 0 .15rem .25rem rgba(0,0,0,.5);
  text-decoration: none;
  font-weight: 510;
}

.container img#menu {
  max-width: 5rem;
}

.menu-items {
  min-width: 23.625rem;
  display: flex;
  align-items: center;
}
.menu-items a {
  margin-right: 2.625rem;
  text-align: right;
}

.mobile-menu {
  display: none;
}

.mobile-navigation {
  position: fixed;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: #211539;
  z-index: 40;
  opacity:1;
}
.mobile-container {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  left: 0;
  justify-content: center;
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 20;
}

.mobile-container .nav-thanks {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 8vh 1.688rem;
}

.mobile-menu-items {
  display: flex;
  flex-direction: column;
  position: relative;
}

.mobile-navigation a {
  padding: 10vh 0;
  border-top: 0.063rem solid #ffffff;
  border-bottom: 0.063rem solid #ffffff;
  color: #fff;
  font-size: 3.20197rem;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

.mobile-footer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5.5rem;
  border-top: 0.063rem solid #ffffff;
  color: #fff;
  justify-content: space-evenly;
}

.mobile-footer span {
  font-size: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #fff;
}

.contact {
  display: flex;
  flex-direction: column;
  padding: 10vh 1.688rem;
}

input {
  background-color: transparent;
  border-radius: 0.5rem;
  border: 0.063rem solid #ffffff;
  text-indent: 0.625rem;
  padding: 0.6rem;
}
@media (max-width: 991px) {
  .contact input {
    height: 2.438rem;
    margin-bottom: 1.313rem;
  }
}

.contact input::placeholder {
  font-size: 1rem;
  font-style: italic;
}

.shadow {
  position: absolute;
  width: 796.063rem;
  height: 25.18rem;
  left: -12rem;
  bottom: -25em;

  background: #5a64ff;
  filter: blur(25rem);
  transform: matrix(1, 0, 0.07, 1, 0, 0);
}

@media (max-width: 374px) {
  .contact {
    padding: 8vh 1.68rem;
  }
  .mobile-navigation a {
    font-size: 1rem;
    padding: 2vh 0;
  }
  .mobile-container {
    top: 3vh;
  }
}

@media (max-width: 767px) {
  .mobile-navigation a {
    padding: 6vh 0;
  }
  .navigation {
    max-height: 5.438rem;
  }
}

/* TABLET/IPAD */
@media (max-width: 991px) {
  .menu-items {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
}
/* END TABLET/IPAD */

/* @media (max-height: 43.75rem) {
    .header-section {
      min-height: 120vh;
    }
  } */

/* COINBASE WALLET */
@media (max-width: 26rem) and (max-height: 41.7rem) {
}

/*END COINBASE WALLET */

@media (max-width: 26rem) and (max-height: 30rem) {
}

@media screen and (max-width: 64rem) and (orientation: landscape) {
}

.button {
  padding: 0.625rem 1.2rem;
  background: #f4526a;
  color: #fff;
  border-radius: 0.5rem;
  border-color: transparent;
  font-size: 1rem;
  /*max-height: 2.438rem;*/
  min-width: 6.5rem;
}

.button:hover {
  opacity: 0.9;
}

@media (max-width: 73.5rem) {
  .button {
    font-size: 1rem;
  }
}

.App {
  position: relative;
  flex: 1;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.support {
  height: 100%;
  display: flex;
  min-height: calc(100vh - 40rem);
  padding: 10em 2.188em;
  color: #fff;
  overflow: hidden;
}

.support_container {
  flex: 1;
  max-width: 82.25rem;
  width: 100%;
  margin: 0 auto;
  font-size: 1.25rem;
  line-height: 1.25;
  display: flex;
  flex-direction: column;

  & h2 {
    margin: 0.5rem 0;
  }

  & h3 {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  & ol li {
    margin: 0.5rem;
  }

  & a {
    font-size: 2rem;
    line-height: 1.25;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }

  & .support_link {
    color: #5A64FF;
    padding: 2rem;
  }

  & .goodbye {
    font-size: 1.5rem;
    max-width: 70vw;
    text-align: center;
    align-self: center;
  }
}



.terms {
  padding: 10em 2.188em;
  color: #fff;
  overflow: hidden;
}

.terms__container {
  max-width: 82.25rem;
  width: 100%;
  margin: 0 auto;
}

.terms,
.terms a {
  color: #d5d5db;
  font-size: 1.375rem;
}

.terms h1 {
  font-size: 2.25em;
}

.terms ol, .terms ul {
  margin-left: 2rem;
}

.terms ol ol,
.terms ul ul {
  margin-left: 0;

}

.terms ol ol {
  list-style: lower-alpha;
}

.terms li {
  margin: 1rem 0;
}

.terms h2,
.terms h3,
.terms h4 {
  margin: 2rem 0 1rem;
}
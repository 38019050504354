@import "./vars.scss";

h1 {
  font-family: $font-h;
  font-style: normal;
  font-weight: bold;
  font-size: 5.115rem;
  line-height: 1.1;

  color: #FFFFFF;
  margin: 1rem 0;
}

h2 {
  font-family: $font-h;
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1.1;

  color: #FFFFFF;
  margin: 1rem 0;
}

p {
  font-family: $font-p;
  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.3;
  letter-spacing: -0.01em;

  color: #FFFFFF;
  margin: 1rem 0;
}
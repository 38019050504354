#trigger-cta-section {
  min-height: 100vh;
  height: max-content;
  display: flex;
  align-items: center;
}
.cta-section {
  @import "../../../styles/vars.scss";
  display: flex;
  color: $text-color;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: sticky;
  top:0;
  opacity: 0;
  height: 100%;

  button {
    max-width: 14em;
    font-size: 1em;
  }
  p {
    font-size: 2.25em;
    max-width: 40.438rem;
    text-align: center;
  }

  button,
  p {
    // opacity: 0;
    // transition: opacity 0.5s;
  }

  .text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .cta-thanks {
      text-align: center;
      p {
        font-size: 1.375rem;
      }
      button {
        padding-left: 5rem;
        padding-right: 5rem;
      }
    }
  }

  .footer {
    bottom: 0%;
    left: 0;
    transition: transform 0.5s;
    position: absolute;
  }
  form {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.cta-contact {
  width: 100%;
  text-align: center;
  display: flex;
  max-width: 30.125rem;

  input {
    width: 100%;
    color: white;
  }
  button {
    transform: translate(1rem, 0px);
  }
}

@media (max-width: 73.5rem) {
  .cta-section {
    padding: 6rem 0 6rem;
  }

  .text-box {
    padding: 1rem 2.5rem;
    width: auto !important;

    button {
      width: 100%;
      max-width: unset;
    }
  }

  .cta-contact {
    flex-direction: column;

    input {
      width: auto;
    }
    input::placeholder {
      font-style: italic;
    }
    button {
      transform: translate(0, 0);
      margin-top: 1rem;
    }
  }
}
#trigger-cards-section {
  min-height: 100vh;
  height: max-content;
}
.cards-section {
  padding: 1rem 2.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: sticky;
  top: 0;
  opacity: 0;

  @import "../../../styles/vars.scss";

  &__container {
    margin: 0 auto;
    max-width: 82.25rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    h2 {
      text-align: center;

      font-family: $font-h;
      font-style: normal;
      font-weight: bold;
      font-size: 2.25rem;
      line-height: 1.1;

      color: #ffffff;
      margin: 1rem 0;

      //  opacity:0;
      transition: opacity 0.5s;
    }
  }
}

.card-list {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  //opacity:0;
  transform: translateY(2rem);
  transition: transform 1s, opacity 0.5s;

  @media (min-width: 992px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
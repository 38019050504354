#trigger-chipset-section{
  min-height: 100vh;
  height: max-content;
  margin-top: 5rem;
}
.chipset-section {
  padding: 1rem 2.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-height: 100vh;

  position: sticky;
  top: 0;
  opacity: 0;

  @media(max-width: 991px) {
    flex-direction: column-reverse;
    padding: 1rem 0;
  }

  @import '../../../styles/text.scss';

  &__container {
    margin: 0 auto;
    max-width: 82.25rem;
    width: 100%;

    @media(max-width: 991px) {
      justify-content: center;
      p, h2 {
        padding: 0 2.5rem;
      }
    }
  }

  &__text {
    max-width: 29.188rem;
    width: 100%;
  }

  &__chip {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;

    img {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      object-fit: contain;
      object-position: center;
    }
  }
}
